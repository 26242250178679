import { css, cx } from '@emotion/css';
import { lazy, Suspense } from 'react';

import { ErrorBoundary } from 'ms-components/ErrorBoundary/ErrorBoundary';
import { colors } from 'ms-styles/colors';

// 🚨🚨🚨 Do not change this from a type import. Importing any runtime symbol
// will force this into our bundle, which is what we are trying to avoid.
import type { Props } from './BunnyVideo';

const BunnyVideo = lazy(async () => {
  const module = await import('./BunnyVideo');
  return { default: module.BunnyVideo };
});

export function LazyBunnyVideo(props: Props) {
  return (
    <ErrorBoundary name="BunnyVideo" fallback={<ErrorMessage />}>
      <Suspense fallback={<LoadingMessage />}>
        <BunnyVideo {...props} />
      </Suspense>
    </ErrorBoundary>
  );
}

function ErrorMessage() {
  return (
    <div className={cx(styles.fallbackView, styles.errorView)}>
      Error loading video
    </div>
  );
}

function LoadingMessage() {
  return <div className={styles.fallbackView}>Loading video...</div>;
}

const styles = {
  fallbackView: css({
    padding: '18px 0',
    textAlign: 'center',
    fontFamily: 'Proxima Nova',
    fontWeight: 'bold',
    color: colors.cloudBurst,
  }),
  errorView: css({
    color: 'red',
  }),
};
