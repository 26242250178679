import { css, cx } from '@emotion/css';
import { clamp } from 'ramda';
import { useState, useRef, useEffect, type ReactNode } from 'react';
import { createPortal } from 'react-dom';

import {
  fontFamily,
  borderRadiusUI,
  borderRadiusUILarge,
  transition,
  zIndex,
} from 'ms-styles/base';
import { colors as baseColors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { colors } from 'ms-styles/theme/Work';
import useIsExclusivelyTouchScreen from 'ms-utils/hooks/useIsExclusivelyTouchScreen';
import getVerticallyScrollableParent from 'ms-utils/scroll/scrollableParent';
import type { SetState } from 'ms-utils/typescript-utils';

type Props = {
  // If the tooltip is a simple string, pass a title.
  title?: string | undefined;
  // If the tooltip contents is more complex, pass the custom tooltipContent.
  tooltipContent?: ReactNode | null | undefined;
  // @deprecated. Use `tooltipContent` instead.
  renderTooltipContent?: (() => ReactNode) | undefined;
  children: ReactNode;
  maxWidth?: number | undefined;
  isInlineFlex?: boolean | undefined;
  isBlock?: boolean | undefined;
  sunflowerStyle?: boolean | undefined;
  isTooltipInteractive?: boolean | undefined;
  backgroundColor?: string | undefined;
  padding?: number | string | undefined;
  borderRadius?: number | string | undefined;
  onSetIsOpen?: (isOpen: boolean) => void;
  hasRootFullHeight?: boolean | undefined;
} & (
  | {
      isOpen: boolean;
      setIsOpen: SetState<boolean>;
    }
  | {
      isOpen?: never;
      setIsOpen?: never;
    }
);

export default function Tooltip({
  title,
  tooltipContent: _tooltipContent,
  renderTooltipContent,
  children,
  maxWidth,
  isInlineFlex,
  isBlock,
  sunflowerStyle,
  isTooltipInteractive: _isTooltipInteractive = false,
  backgroundColor,
  padding,
  borderRadius,
  onSetIsOpen,
  hasRootFullHeight,
  isOpen: isOpenExternal,
  setIsOpen: setIsOpenExternal,
}: Props) {
  const tooltipContent = _tooltipContent || title;

  const [isOpenState, setIsOpenState] = useState(false);
  const [isAboveAnchor, setIsAboveAnchor] = useState(false);
  const isOpen = isOpenExternal ?? isOpenState;
  const setIsOpen = setIsOpenExternal ?? setIsOpenState;

  const isExclusivelyTouchScreen = useIsExclusivelyTouchScreen();

  // In touch devices, tooltip only closes with a click outside
  // or a click on the root/children
  // which basically makes the tooltip an interactive tooltip.
  // It should also be regarded as interactive tooltip
  // to prevent the click on the tooltip
  // being interpreted as a click for an element behind the tooltip,
  // even if the tooltip isn't interactive,
  // or even if it doesn't have any clickable element.
  const isTooltipInteractive =
    _isTooltipInteractive || isExclusivelyTouchScreen;

  const [pos, setPos] = useState({ left: 0, top: 0 });

  const root = useRef<HTMLDivElement | null>(null);
  const tooltip = useRef<HTMLDivElement | null>(null);
  const svg = useRef<SVGSVGElement | null>(null);
  const path = useRef<SVGPathElement | null>(null);
  const scrollableParent = useRef<Element | null>(null);

  // We capture the scrollY and anchorHeight at the time of mouseover, as
  // our scroll handler needs to use this info to determine if the tooltip
  // should be dismissed as the result of a scroll operation.
  const atMouseOverRef = useRef<{
    scrollX: number | undefined;
    scrollY: number | undefined;
    anchorWidth: number;
    anchorHeight: number;
  } | null>(null);

  // mouseOut events are not fired while scroll events are occuring. In practice
  // this means you can hover a tooltip anchor and get the tooltip, then scroll
  // down the page with your trackpad and the tooltip will still be present even
  // though you aren't hovering the tooltip anchor anymore. This scroll handler
  // fixes that behaviour so that the tooltip disappear when its anchor is unhovered
  useEffect(() => {
    if (!isOpen || scrollableParent.current == null) return;

    function handleScroll() {
      const atMouseOver = atMouseOverRef.current;
      if (atMouseOver === null) return;
      // If we have panned further than the height of the anchor node we
      // are guaranteed to no longer be hovering the anchor and should hide
      // the tooltip.
      const { scrollX, scrollY, anchorWidth, anchorHeight } = atMouseOver;

      if (scrollY == null || scrollX == null) return;

      const currentScrollTop = scrollableParent.current?.scrollTop;
      const currentScrollLeft = scrollableParent.current?.scrollLeft;

      if (currentScrollTop == null || currentScrollLeft == null) return;

      if (
        Math.abs(scrollY - currentScrollTop) > anchorHeight ||
        Math.abs(scrollX - currentScrollLeft) > anchorWidth
      ) {
        handleClose();
      }
    }

    scrollableParent.current.addEventListener('scroll', handleScroll);
    return () => {
      scrollableParent.current?.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, scrollableParent]);

  useEffect(() => {
    if (scrollableParent.current == null && root.current != null) {
      const scrollParent = getVerticallyScrollableParent(root.current);

      if (scrollParent != null) {
        scrollableParent.current = scrollParent;
      }
    }
  }, [root]);

  function handleOpen() {
    if (isOpen) return;

    const rootDiv = root.current;
    const tooltipDiv = tooltip.current;
    if (rootDiv === null || tooltipDiv === null) return;

    const rootRect = rootDiv.getBoundingClientRect();
    const tooltipRect = tooltipDiv.getBoundingClientRect();
    const clientWidth = document.body?.clientWidth;
    const clientHeight = document.body?.clientHeight;

    // We want to get the current offset parent and subtract it from the positions of the bounding client rect
    // There are cases where the popover may be inside an element with a transform or will-change (like the modal)
    // that causes the containing block to not be the viewport
    // In browsers that are not firefox, this can be nullable if the containing parent is display:none or is the
    // <body> or <html> elements themselves. In those cases, it's safe to assume that the offsets are 0
    const positionedParent = rootDiv.offsetParent;

    let offsetLeft = 0;
    let offsetTop = 0;
    if (positionedParent != null) {
      const { willChange, transform, filter, contain } =
        getComputedStyle(positionedParent);
      // https://developer.mozilla.org/en-US/docs/Web/CSS/Containing_block
      if (
        willChange === 'transform' ||
        willChange === 'perspective' ||
        transform !== 'none' ||
        filter !== 'none' ||
        contain === 'paint'
      ) {
        const { left, top } = positionedParent.getBoundingClientRect();
        offsetLeft = left;
        offsetTop = top;
      }
    }

    const positionedParentWidth = positionedParent?.clientWidth;
    if (clientWidth == null || clientHeight == null) return;

    // Save some UI state at time of mouseover for later use in the scroll handler.
    atMouseOverRef.current = {
      scrollX: scrollableParent.current?.scrollLeft,
      scrollY: scrollableParent.current?.scrollTop,
      anchorWidth: rootRect.width,
      anchorHeight: rootRect.height,
    };

    // Compute left/top position for the tooltip
    const idealLeft =
      rootRect.left + rootRect.width / 2 - tooltipRect.width / 2 - offsetLeft;
    const minLeft = 0 + BUFFER;
    const maxLeft =
      (offsetLeft > 0 && positionedParentWidth != null
        ? positionedParentWidth
        : clientWidth) -
      tooltipRect.width -
      BUFFER;
    const left =
      minLeft < maxLeft ? clamp(minLeft, maxLeft, idealLeft) : minLeft;

    // Check if the tooltip is below the viewport and there's enough space to
    // show it above the anchor. If both conditions are met, the tooltip will
    // be shown above the anchor, otherwise it will be shown below the anchor
    const isBelowViewport = rootRect.top + tooltipRect.height > clientHeight;
    const isSpaceAvailableUpside = rootRect.top - tooltipRect.height > 0;
    const showAboveAnchor = isBelowViewport && isSpaceAvailableUpside;
    const top = showAboveAnchor
      ? rootRect.top - BUFFER - tooltipRect.height + offsetTop
      : rootRect.bottom + BUFFER - offsetTop;

    setIsAboveAnchor(showAboveAnchor);
    setPos({ left, top });
    setIsOpen(true);
    onSetIsOpen?.(true);
  }

  function handleClose() {
    const rootDiv = root.current;
    if (rootDiv === null) return;
    atMouseOverRef.current = null;
    setIsOpen(false);
    onSetIsOpen?.(false);
  }

  const [portalDiv, setPortalDiv] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    const div = document.createElement('div');
    document.body.appendChild(div);
    setPortalDiv(div);
    return () => {
      document.body.removeChild(div);
    };
  }, []);

  // This creates an SVG overlay area for interactive tooltip to work properly
  // without needing to set any delays which causes issues.
  // See below for more info on this technique:
  // https://www.smashingmagazine.com/2021/05/frustrating-design-patterns-mega-dropdown-hover-menus/#svg-path-exit-areas
  useEffect(() => {
    if (!isTooltipInteractive || !isOpen) return;

    const svgEl = svg.current;
    const pathEl = path.current;
    if (svgEl == null || pathEl == null) return;

    const rootDiv = root.current;
    const tooltipDiv = tooltip.current;
    if (rootDiv == null || tooltipDiv == null) return;

    const rootRect = rootDiv.getBoundingClientRect();
    const tooltipRect = tooltipDiv.getBoundingClientRect();

    const height = rootRect.height + BUFFER;
    const width = tooltipRect.width;

    svgEl.setAttribute('height', `${height}`);
    svgEl.setAttribute('width', `${width}`);
    svgEl.style.left = `${tooltipRect.left}`;

    // These are relative coordinates
    const rootLeft = rootRect.left - tooltipRect.left;
    const rootRight = rootLeft + rootRect.width;

    let d;

    if (isAboveAnchor) {
      svgEl.style.top = `${rootRect.bottom - height}`;
      d = `M ${rootRight} ${height} Q ${rootRight} 0, ${width} 0 h ${-width} Q ${rootLeft} 0, ${rootLeft} ${height} v ${-rootRect.height} h ${
        rootRect.width
      } v ${rootRect.height} Z`;
    } else {
      svgEl.style.top = `${rootRect.top}`;
      d = `M ${rootRight} 0 Q ${rootRight} ${height}, ${width} ${height} h ${-width} Q ${rootLeft} ${height}, ${rootLeft} 0 v ${
        rootRect.height
      } h ${rootRect.width} v ${-rootRect.height} Z`;
    }

    pathEl.setAttribute('d', d);
  }, [isOpen, pos.top, pos.left, isTooltipInteractive, isAboveAnchor]);

  function toggleTooltipOnTouchDevice(event: React.MouseEvent) {
    if (
      // Only for touch devices
      !isExclusivelyTouchScreen ||
      // If the click is on the tooltip, we don't want to close it.
      (event.target instanceof HTMLElement && portalDiv?.contains(event.target))
    )
      return;

    return isOpen ? handleClose() : handleOpen();
  }

  // This makes sure the tooltip is closed when the tooltip is open
  // and the user clicks anywhere outside any of the tooltip elements
  // on touch devices.
  useEffect(() => {
    if (!isExclusivelyTouchScreen) return;

    const handleClickOutside = (event: Event) => {
      // Closing the tooltip when clicking outside any of the tooltip elements
      if (
        isOpen &&
        event.target instanceof HTMLElement &&
        !root.current?.contains(event.target) &&
        !portalDiv?.contains(event.target)
      ) {
        handleClose();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => document.removeEventListener('click', handleClickOutside);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, isExclusivelyTouchScreen]);

  return (
    <div
      ref={root}
      className={cx([
        isInlineFlex ? styles.tooltipRootInlineFlex : styles.tooltipRoot,
        isBlock != null && styles.tooltipRootBlock,
        hasRootFullHeight && styles.hasRootFullHeight,
      ])}
      onMouseEnter={isExclusivelyTouchScreen ? undefined : handleOpen}
      onMouseLeave={isExclusivelyTouchScreen ? undefined : handleClose}
      onClick={
        isExclusivelyTouchScreen ? toggleTooltipOnTouchDevice : undefined
      }
    >
      {children}
      {/* We will always render the tooltip to keep layout measurement simpler */}
      {portalDiv != null &&
        createPortal(
          <>
            {isTooltipInteractive && (
              <svg
                ref={svg}
                className={styles.svgOverlay}
                style={{
                  display: isOpen ? 'block' : 'none',
                }}
                fill="transparent"
                fillOpacity={0} // for old browsers
              >
                <path ref={path} className={styles.svgOverlayPath} />
              </svg>
            )}
            <div
              ref={tooltip}
              className={cx([
                styles.tooltip,
                isTooltipInteractive && isOpen && styles.pointerEventsEnabled,
                sunflowerStyle && styles.sunflowerTooltip,
              ])}
              style={{
                top: pos.top,
                left: pos.left,
                opacity: isOpen ? 1 : 0,
                ...(maxWidth != null && { maxWidth }),
                ...(backgroundColor != null && { backgroundColor }),
                ...(padding != null && { padding }),
                ...(borderRadius != null && { borderRadius }),
              }}
            >
              {tooltipContent ||
                (renderTooltipContent != null && renderTooltipContent())}
            </div>
          </>,
          portalDiv,
        )}
    </div>
  );
}

const BUFFER = 2 * BASE_UNIT; // distance between the anchor and the tooltip
const BACKGROUND_COLOR = colors.pickledBluewood;
const BACKGROUND_COLOR_SUNFLOWER = colors.gray;
export const PADDING_X = 2.5 * BASE_UNIT; // legacy :(
export const PADDING_Y = 2 * BASE_UNIT;

const styles = {
  tooltipRoot: css({
    display: 'inline-block',
  }),
  tooltipRootInlineFlex: css({
    display: 'inline-flex',
  }),
  tooltipRootBlock: css({
    display: 'block', // inline-block and inline-flex break text-overflow: ellipsis on children
  }),
  hasRootFullHeight: css({
    height: '100%',
  }),
  tooltip: css({
    fontFamily: fontFamily.body,
    position: 'fixed',
    color: baseColors.white,
    borderRadius: borderRadiusUI,
    padding: `${PADDING_Y}px ${PADDING_X}px`,
    transition: `opacity ${transition}`,
    pointerEvents: 'none',
    zIndex: zIndex.tooltip,
    backgroundColor: BACKGROUND_COLOR,
    lineHeight: 1.3,
  }),
  sunflowerTooltip: css({
    borderRadius: borderRadiusUILarge,
    backgroundColor: BACKGROUND_COLOR_SUNFLOWER,
  }),
  pointerEventsEnabled: css({
    pointerEvents: 'auto',
  }),
  svgOverlay: css({
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: zIndex.tooltip,
  }),
  svgOverlayPath: css({
    pointerEvents: 'auto',
  }),
};
