import { css } from '@emotion/css';
import { useEffect, useImperativeHandle, useRef, useState } from 'react';

import { fontWeight } from 'ms-styles/base';
import { Logger } from 'ms-utils/app-logging';
import { useUnmountEffect } from 'ms-utils/hooks/useUnmountEffect';
import { assert, assertUnreachable } from 'ms-utils/typescript-utils';
import type { DesmosCalculatorT } from 'third-party/desmos/desmos-calculator';

import type { AngleUnit, ImperativeHandle } from './CalculatorPopover';
import './DesmosCalculatorFixes.css';

// Used to cache the latest state of a given calculator when the user
// closes/switches away from a given calculator so that we can restore the
// state when they return to that calculator kind
type CalculatorState = unknown; // should treat as opaque
const calculatorStateCache: Partial<
  Record<'scientific' | 'graphing', CalculatorState>
> = {};

export function DesmosCalculator({
  kind,
  angleUnit,
  onChangeAngleUnit,
  calculatorRef,
  onLoad,
}: {
  kind: 'scientific' | 'graphing';
  angleUnit?: AngleUnit | undefined;
  onChangeAngleUnit: (angleUnit: AngleUnit) => void;
  calculatorRef?: React.Ref<ImperativeHandle> | undefined;
  onLoad?: (() => void) | undefined;
}) {
  if (kind === 'scientific') {
    return (
      <DesmosScientificCalculator
        angleUnit={angleUnit}
        onChangeAngleUnit={onChangeAngleUnit}
        calculatorRef={calculatorRef}
        onLoad={onLoad}
      />
    );
  } else if (kind === 'graphing') {
    return (
      <DesmosGraphingCalculator
        angleUnit={angleUnit}
        onChangeAngleUnit={onChangeAngleUnit}
        calculatorRef={calculatorRef}
        onLoad={onLoad}
      />
    );
  }

  assertUnreachable(kind);
}

// Virginia uses a specially configured Desmos calculator for their state-wide tests.
// - https://www.desmos.com/state-pdfs/VA_Desmos_Calculators.pdf
// - https://www.desmos.com/testing/virginia/scientific
// - https://www.desmos.com/testing/virginia/graphing
//
// Since the Desmos calculator is primarily being integrated as a selling tactic for
// Virginia we want to match the behaviour of their test calculator. Unfortunately
// the exact config is not documented. I have extracted the config by using an interactive
// debugger with the test calculators listed above. The process is outlined here:
// https://paper.dropbox.com/doc/Extracting-Desmos-Test-configs--Cel9KAiBNVT16ZKGQRh_EIQQAg-7v38l12gjPxJAwnezgY9A
const virginiaTestConfig = {
  scientific: {
    functionDefinition: false,
    decimalToFraction: false,
    links: false,
    brailleExpressionDownload: false,
    capExpressionSize: true,
  },
  graphing: {
    images: false,
    folders: false,
    links: false,
    notes: false,
    restrictedFunctions: true,
    degreeMode: true,
    clearIntoDegreeMode: true,
    branding: false,
    border: false,
    decimalToFraction: true,
    distributions: true,
    forceEnableGeometryFunctions: true,
    tone: false,
    capExpressionSize: true,
  },
};

function DesmosScientificCalculator({
  angleUnit = 'degree',
  onChangeAngleUnit,
  calculatorRef,
  onLoad,
}: {
  angleUnit?: AngleUnit | undefined;
  onChangeAngleUnit: (angleUnit: AngleUnit) => void;
  calculatorRef?: React.Ref<ImperativeHandle> | undefined;
  onLoad?: (() => void) | undefined;
}) {
  const rootRef = useRef<HTMLDivElement>(null);
  const calc = useRef<DesmosCalculatorT | null>(null);
  const onChangeAngleUnitRef = useRef(onChangeAngleUnit);
  const [isLibLoading, setIsLibLoading] = useState(!hasLibraryBeenLoaded);
  const [moduleLoadFailed, setModuleLoadFailed] = useState(false);

  useImperativeHandle(calculatorRef, () => ({
    reset: () => {
      if (calc.current === null) return;
      calc.current?.setBlank();
    },
  }));

  useEffect(() => {
    const rootElement = rootRef.current;
    assert(rootElement !== null, 'Root ref should be set');
    let isStaleEffect = false;
    loadDesmosLibrary()
      .then(({ ScientificCalculator }) => {
        if (isStaleEffect) return;
        setIsLibLoading(false);
        const c = (calc.current = ScientificCalculator(rootElement, {
          ...virginiaTestConfig.scientific,
          border: false,
          degreeMode: angleUnit === 'degree',
        }));
        if (calculatorStateCache.scientific) {
          c.setState(calculatorStateCache.scientific);
          // The persisted state includes the degree mode, so we need to
          // make sure that doesn't take effect, as it's the degree mode
          // across all calculators that is our source of truth.
          c.updateSettings({ degreeMode: angleUnit === 'degree' });
        }
        c.settings.observe('degreeMode', () => {
          onChangeAngleUnitRef.current(
            c.settings.degreeMode ? 'degree' : 'radiant',
          );
        });
        onLoad?.();
      })
      .catch(() => {
        setIsLibLoading(false);
        setModuleLoadFailed(true);
        Logger.error('Desmos module failed to load');
      });
    return () => {
      isStaleEffect = true;
    };
    // - angleUnit changing between renders is handled in a separate effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    calc.current?.updateSettings({ degreeMode: angleUnit === 'degree' });
  }, [angleUnit]);

  useUnmountEffect(() => {
    const c = calc.current;
    if (c === null) return;
    calculatorStateCache.scientific = c.getState();
    c.destroy();
  });

  if (moduleLoadFailed) {
    return <div className={styles.loadError}>Failed to load calculator</div>;
  }

  return (
    <div className={styles.stackRoot}>
      <div className={styles.desmosRoot} ref={rootRef} />
      {isLibLoading && <DesmosLoadingIndicator />}
    </div>
  );
}

function DesmosLoadingIndicator() {
  return <div className={styles.loadingIndicator}>Loading...</div>;
}

function DesmosGraphingCalculator({
  angleUnit,
  onChangeAngleUnit,
  calculatorRef,
  onLoad,
}: {
  angleUnit?: AngleUnit | undefined;
  onChangeAngleUnit: (angleUnit: AngleUnit) => void;
  calculatorRef?: React.Ref<ImperativeHandle> | undefined;
  onLoad?: (() => void) | undefined;
}) {
  const rootRef = useRef<HTMLDivElement>(null);
  const calc = useRef<any>(null);
  const onChangeAngleUnitRef = useRef(onChangeAngleUnit);
  const [isLibLoading, setIsLibLoading] = useState(!hasLibraryBeenLoaded);
  const [moduleLoadFailed, setModuleLoadFailed] = useState(false);

  useImperativeHandle(calculatorRef, () => ({
    reset: () => {
      if (calc.current === null) return;
      calc.current.setBlank();
    },
  }));

  // Maintain a ref to the latest committed onChangeAngleUnit function
  // so that our 'change' observer setup in the calculator initialization
  // always calls back to the latest function.
  useEffect(() => {
    onChangeAngleUnitRef.current = onChangeAngleUnit;
  }, [onChangeAngleUnit]);

  useEffect(() => {
    const rootElement = rootRef.current;
    assert(rootElement !== null, 'Root ref should be set');
    let isStaleEffect = false;
    loadDesmosLibrary()
      .then(({ GraphingCalculator }) => {
        if (isStaleEffect) return;
        setIsLibLoading(false);
        const c = (calc.current = GraphingCalculator(rootElement, {
          ...virginiaTestConfig.graphing,
          border: false,
          degreeMode: angleUnit === 'degree',
        }));
        if (calculatorStateCache.graphing) {
          c.setState(calculatorStateCache.graphing);
          // The persisted state includes the degree mode, so we need to
          // make sure that doesn't take effect, as it's the degree mode
          // across all calculators that is our source of truth.
          c.updateSettings({ degreeMode: angleUnit === 'degree' });
        }
        c.settings.observe('degreeMode', () => {
          onChangeAngleUnitRef.current(
            c.settings.degreeMode ? 'degree' : 'radiant',
          );
        });
        onLoad?.();
      })
      .catch(() => {
        setIsLibLoading(false);
        setModuleLoadFailed(true);
        Logger.error('Desmos module failed to load');
      });
    return () => {
      isStaleEffect = true;
    };
    // - angleUnit changing between renders is handled in a separate effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    calc.current?.updateSettings({ degreeMode: angleUnit === 'degree' });
  }, [angleUnit]);

  useUnmountEffect(() => {
    const c = calc.current;
    if (c === null) return;
    calculatorStateCache.graphing = c.getState();
    c.destroy();
  });

  if (moduleLoadFailed) {
    return <div className={styles.loadError}>Failed to load calculator</div>;
  }

  return (
    <div className={styles.stackRoot}>
      <div className={styles.desmosRoot} ref={rootRef} />
      {isLibLoading && <DesmosLoadingIndicator />}
    </div>
  );
}

// We track whether the module is in the webpack module cache so we
// can avoid flickering loading state when it'll resolve immediately.
let hasLibraryBeenLoaded = false;
async function loadDesmosLibrary() {
  const lib = await import('third-party/desmos/desmos-calculator');
  hasLibraryBeenLoaded = true;
  return lib;
}

const styles = {
  stackRoot: css({
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplate: `"stack" 1fr / 1fr`,
  }),
  desmosRoot: css({
    gridArea: 'stack',
    // We must undo the default value of the property for a grid child
    // which is 'auto'. 'auto' means the height of the grid child will
    // grow in an unbounded fashion as a function of its content.
    // min-height: 0; does not, in fact, allow the child to shrink below
    // the size of its grid cell dimensions. It instead (in this situation)
    // means "grid child dimensions must match the dimensions allocated to
    // that cell by the grid container. The reason this is important is
    // the Desmos lib has internal logic for rejigging its UI to fit in
    // the available space (this desmosRoot node), so we have to give it
    // correct layout dimensions to calibrate to.
    minHeight: 0,
  }),
  loadingIndicator: css({
    gridArea: 'stack',
    display: 'grid',
    placeItems: 'center',
  }),
  loadError: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    color: 'red',
    fontWeight: fontWeight.semibold,
  }),
};
