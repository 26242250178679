import { css, injectGlobal, keyframes } from '@emotion/css';

/**
 * Create a counter animation that animates a CSS variable from 30 points below the end value to the end value.
 * Takes care of registering the relevant CSS properties and keyframes.
 * Adds the animated value as content of the `::after` pseudo-element.
 * @param targetValuePropertyName The name of the CSS variable with the target value to count up to. e.g. "--points-card-points"
 * @param duration The duration of the animation. Default is '2s'.
 * @param startDelta The amount below the target value to start counting from. Default is 30.
 * @returns The className of the counter animation.
 */
export default function createCounter(
  targetValuePropertyName: `--${string}`,
  {
    duration = '2s',
    startDelta = 30,
  }: { duration?: string; startDelta?: number } = {},
): ReturnType<typeof css> {
  const propertyNameParts = targetValuePropertyName
    .replace(/^--/, '')
    .split('-');
  const propertyNameBase = propertyNameParts
    .slice(0, Math.max(propertyNameParts.length - 1, 1))
    .join('-');
  const currentValuePropertyName = `--${propertyNameBase}-int`;
  const counterKeyframesClass = keyframes({
    from: {
      // Start the anim 30 (by default) below the end value. This value is arbitrary,
      // but seems to work OK. We also prevent negative values.
      [currentValuePropertyName]: `max(0, var(${targetValuePropertyName}) - ${startDelta})`,
    },
    to: {
      [currentValuePropertyName]: `var(${targetValuePropertyName})`,
    },
  });

  const counterCss = css({
    animation: `${duration} forwards ${counterKeyframesClass}`,
    counterReset: `value var(${currentValuePropertyName})`,

    '::after': {
      content: 'counter(value)',
    },
  });

  injectGlobal({
    [`@property ${currentValuePropertyName}`]: {
      syntax: '"<integer>"',
      inherits: 'false',
      initialValue: 0,
    },
    [`@property ${targetValuePropertyName}`]: {
      syntax: '"<integer>"',
      inherits: 'false',
      initialValue: 0,
    },
  });

  return counterCss;
}
