import type { ReactNode } from 'react';
import { useEffect } from 'react';

import { EASE_IN_OUT, useAnimation } from 'ms-utils/animation/animation';
import { usePrevious } from 'ms-utils/hooks/usePrevious';

import Arc from './Arc';

const { PI: π } = Math;

type Progress = {
  value: number; // percent in range [0, 100]
  color: string;
};

type Props = {
  size: number; // pixels
  progress: Progress;
  secondaryProgress?: Progress | undefined;
  backgroundColor: string;
  thickness?: number | undefined; // pixels
  withTransparentPadding?: boolean | undefined;
  children?: ReactNode | undefined;
  animated?: boolean | undefined;
};

const VIEWBOX = 100;
const DEFAULT_THICKNESS = VIEWBOX / 12;

export default function DonutProgressBar({
  size,
  progress,
  secondaryProgress,
  backgroundColor,
  children,
  animated,
  thickness = DEFAULT_THICKNESS,
  withTransparentPadding = true,
}: Props) {
  const previousProgressValue = usePrevious(progress.value);
  const { value: _progressValue, restart: restartProgressAnimation } =
    useAnimation({
      duration: 1000,
      curve: EASE_IN_OUT,
      values: [0, progress.value],
      startPaused: progress.value === 0 || previousProgressValue === 0,
    });
  const progressValue = animated ? _progressValue : progress.value;

  const previousSecondaryValue = usePrevious(secondaryProgress?.value);
  const secondaryProgressValueNonNull = secondaryProgress?.value ?? 0;
  const { value: _secondaryProgressValue, restart: restartSecondaryAnimation } =
    useAnimation({
      duration: 1000,
      curve: EASE_IN_OUT,
      values: [0, secondaryProgressValueNonNull],
      startPaused:
        secondaryProgressValueNonNull === 0 || previousSecondaryValue === 0,
    });
  const secondaryProgressValue = animated
    ? _secondaryProgressValue
    : secondaryProgress?.value ?? null;

  const paddingProgress =
    secondaryProgress == null
      ? progressValue
      : Math.max(progressValue, secondaryProgressValue ?? 0);

  // Start the animation when the progress values change from a non-zero value
  useEffect(() => {
    if (animated && progress.value > 0 && previousProgressValue === 0) {
      restartProgressAnimation();
    }
  }, [
    animated,
    progress.value,
    previousProgressValue,
    restartProgressAnimation,
  ]);

  useEffect(() => {
    if (
      animated &&
      secondaryProgressValueNonNull > 0 &&
      previousSecondaryValue === 0
    ) {
      restartSecondaryAnimation();
    }
  }, [
    animated,
    secondaryProgressValueNonNull,
    previousSecondaryValue,
    restartSecondaryAnimation,
  ]);

  return (
    <div style={{ position: 'relative', height: size, width: size }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox={`0 0 ${VIEWBOX} ${VIEWBOX}`}
      >
        {/* The mask is needed to create the transparent padding */}
        {withTransparentPadding && (
          <mask id="mask">
            <rect x="0" y="0" width={VIEWBOX} height={VIEWBOX} fill="white" />
            <Arc
              cx={VIEWBOX / 2}
              cy={VIEWBOX / 2}
              ρ={VIEWBOX / 2 - thickness / 2}
              Δ={(paddingProgress / 100) * 2 * π}
              thickness={thickness * 1.6}
              color="black"
            />
          </mask>
        )}

        <circle
          cx={VIEWBOX / 2}
          cy={VIEWBOX / 2}
          r={VIEWBOX / 2 - thickness / 2}
          strokeWidth={thickness}
          fill="transparent"
          stroke={backgroundColor}
          {...(withTransparentPadding ? { mask: 'url(#mask)' } : {})}
        />

        {/* the order of Arc elements is important so that the progress is always shown on top of the secondaryProgress */}
        {secondaryProgress != null && secondaryProgressValue && (
          <Arc
            cx={VIEWBOX / 2}
            cy={VIEWBOX / 2}
            ρ={VIEWBOX / 2 - thickness / 2}
            thickness={thickness}
            color={secondaryProgress.color}
            Δ={(secondaryProgressValue / 100) * 2 * π}
          />
        )}

        <Arc
          cx={VIEWBOX / 2}
          cy={VIEWBOX / 2}
          ρ={VIEWBOX / 2 - thickness / 2}
          thickness={thickness}
          color={progress.color}
          Δ={(progressValue / 100) * 2 * π}
        />
      </svg>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: size,
          width: size,
        }}
      >
        {children}
      </div>
    </div>
  );
}
