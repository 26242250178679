import styled from '@emotion/styled';
import moment from 'moment';
import type { ComponentProps } from 'react';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, graphql, useFragment } from 'relay-hooks';

import { LeaderboardModal as LeaderboardModalV2 } from 'ms-experiments/gamification/LeaderboardModal';
import { LightningBoltSvg } from 'ms-experiments/gamification/LightningBoltSvg';
import { useSnowplow } from 'ms-helpers/Snowplow';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import useFeatureFlags from 'ms-helpers/useFeatureFlags';
import useFeatureFlagsV2 from 'ms-helpers/useFeatureFlagsV2';
import { SkillProficiencyIndicator } from 'ms-pages/Lantern/components/SkillProficiencyIndicator';
import StartCheckInButton from 'ms-pages/Lantern/components/StartCheckInButton';
import { HeadingS, BodyM } from 'ms-pages/Lantern/primitives/Typography';
import {
  getStudentSkillUrl,
  getStudentSkillsUrl,
} from 'ms-pages/Lantern/utils/urls';
import { START_CHECK_IN_DISABLED_TOOLTIP_CONTENT } from 'ms-pages/Lantern/views/Student/CheckIn/utils';
import {
  LeaderboardModal as LeaderboardModalV1,
  LEADERBOARD_TOP_N,
} from 'ms-pages/StudentDashboard/Leaderboard';
import { makeCheckInButtonConfig } from 'ms-pages/StudentDashboard/SunflowerStudentDashboard/SunflowerStudentDashboardHeader/SunflowerStudentDashboardHeaderWithLearningFocus';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import { formatDuration } from 'ms-pages/Teacher/utils/time';
import { fontSize, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import AnchorButton from 'ms-ui-primitives/Button/AnchorButton';
import Popover from 'ms-ui-primitives/Popover/PopoverInternal';
import { HStack, HSpacer, VStack, VSpacer } from 'ms-ui-primitives/Stack';
import { Logger } from 'ms-utils/app-logging';
import { multilineTextOverflow } from 'ms-utils/emotion';
import getProficiencyForOutcome from 'ms-utils/getProficiencyForOutcome';
import { useBoolean } from 'ms-utils/hooks/useBoolean';
import _ from 'ms-utils/relay/extractNode';
import { getStartOfWeek } from 'ms-utils/time';
import { assert, assertUnreachable, unwrap } from 'ms-utils/typescript-utils';
import { getWorkoutUrl, getSelfDirectedAdaptiveTaskUrl } from 'ms-utils/urls';

import Slider from './Slider';
import type {
  NavbarPopoverContentQuery,
  NavbarPopoverContentQueryResponse as ContentQueryResponse,
} from './__generated__/NavbarPopoverContentQuery.graphql';
import type {
  NavbarPopoverQuery,
  NavbarPopoverQueryResponse,
  TaskTypes,
} from './__generated__/NavbarPopoverQuery.graphql';
import type { NavbarPopoverSkillsCollectedQuery } from './__generated__/NavbarPopoverSkillsCollectedQuery.graphql';
import type { NavbarPopoverSkillsCollectedStartCheckInButtonQuery } from './__generated__/NavbarPopoverSkillsCollectedStartCheckInButtonQuery.graphql';
import type { NavbarPopover_NavbarElement_user$key } from './__generated__/NavbarPopover_NavbarElement_user.graphql';
import type { NavbarPopover_PopoverContent_user$key } from './__generated__/NavbarPopover_PopoverContent_user.graphql';
import SkillBadge from './badges/SkillBadge';

const NOW = moment().format();
const START_OF_WEEK = getStartOfWeek(NOW);
const Nowrap = styled.span({
  whiteSpace: 'nowrap',
});
const NoShrink = styled.div({
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
});

const InlineHeadingS = HeadingS.withComponent('span');
type Viewer = NonNullable<ContentQueryResponse['viewer']>;
type Profile = NonNullable<Viewer['profile']>;
type LanternStudent = Extract<
  NonNullable<NonNullable<ContentQueryResponse['lantern']>['lanternStudent']>,
  {
    __typename: 'LanternStudent';
  }
>;
type LearningFocus = NonNullable<LanternStudent['learningFocus']>;
type SkillsCollectedT =
  | NonNullable<
      Extract<
        Profile,
        {
          __typename: 'Student';
        }
      >['activitySummary']
    >['skillsDemonstrated']
  | null
  | undefined;
type SkillT = NonNullable<SkillsCollectedT>[number];
const NAVBAR_POPOVER_QUERY = graphql`
  query NavbarPopoverQuery(
    $startOfWeek: DateTime!
    $now: DateTime!
    $topN: Int
    $classId: ID
    $fetchLeaderboardModalData: Boolean!
  ) {
    viewer {
      ...NavbarPopover_NavbarElement_user
      ...NavbarPopover_PopoverContent_user
      student: profile {
        __typename
        ... on Student {
          activitySummary(startDate: $startOfWeek, endDate: $now) {
            totalTimeSpent
          }

          ...Leaderboard_student
            @arguments(
              fetchLeaderboard: false
              numberOfClasses: 1000
              topN: $topN
            )
          ...LeaderboardModal_student
            @arguments(
              fetchLeaderboard: $fetchLeaderboardModalData
              numberOfClasses: 1000
              classId: $classId
            )

          classes(first: 1000) {
            edges {
              node {
                hasEnabledLeaderboard
              }
            }
          }

          assignedTasks: assignedTaskConnection(
            first: 1
            excludeNonWorkbookTasks: true
            dueAfter: $now
            orderBy: DUE_DATE_ASCENDING
            excludeEnded: true
          ) {
            edges {
              node {
                id
                type
                workout {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;
const NAVBAR_POPOVER_CONTENT_QUERY = graphql`
  query NavbarPopoverContentQuery(
    $startOfWeek: DateTime!
    $now: DateTime!
    $previewingWithProblemData: Boolean!
    $growthPeriod: Int!
  ) {
    lantern {
      lanternStudent: viewer {
        __typename
        ... on LanternStudent {
          learningFocus {
            id
            canStartCheckIn
            strand {
              id
            }
            studentSubtopicRecommendations {
              category
              subtopic {
                id
              }
            }
          }
          learningFocusStatus
        }
      }
    }

    viewer {
      profile {
        __typename
        ... on Student {
          activitySummary(startDate: $startOfWeek, endDate: $now) {
            skillsDemonstrated(
              previewingWithProblemData: $previewingWithProblemData
              growthPeriod: $growthPeriod
            ) {
              id
              outcome {
                id
                title
                gradeSubstrand {
                  substrand {
                    id
                    strand {
                      id
                    }
                  }
                }
              }
            }
            checkInsCompleted
          }
        }
      }
    }
  }
`;
const NAVBAR_POPOVER_SKILLS_COLLECTED_QUERY = graphql`
  query NavbarPopoverSkillsCollectedQuery(
    $filters: [UserStatusFilterInput!]!
    $previewingWithProblemData: Boolean!
    $growthPeriod: Int!
  ) {
    lantern {
      lanternStudent: viewer {
        __typename
        ... on LanternStudent {
          userStatuses(
            filters: $filters
            previewingWithProblemData: $previewingWithProblemData
            growthPeriod: $growthPeriod
          ) {
            trueProficiency
            userStatusFilter {
              curriculumNodeIds
            }
          }
        }
      }
    }
  }
`;
const WORKBOOK_TASKS: TaskTypes[] = ['ADAPTIVE_TASK', 'CUSTOM_TASK'];
type PopoverTypes = 'ACTIVITY' | 'SKILLS' | 'POINTS';
type Props = {
  isOpen: boolean;
  onClose: () => void;
  type: PopoverTypes;
};
export default function NavbarPopover({ isOpen, onClose, type }: Props) {
  const anchorElementRef = useRef<HTMLDivElement | null>(null);
  const {
    featureFlagsV2: { gamificationEnableLeaderboardModalV2 },
    leaderboardClassId,
  } = useViewer();
  const { props } = useQuery<NavbarPopoverQuery>(NAVBAR_POPOVER_QUERY, {
    startOfWeek: START_OF_WEEK,
    now: NOW,
    topN: LEADERBOARD_TOP_N,
    classId: leaderboardClassId,
    // We only need to fetch the leaderboard data up front for the new leaderboard modal, which is only enabled for users
    // who have the feature flag enabled and who belong to a valid class with the leaderboard features enabled.
    // For all other users, we can skip fetching the leaderboard data up front.
    fetchLeaderboardModalData:
      gamificationEnableLeaderboardModalV2 && leaderboardClassId != null,
  });
  if (props == null) {
    return null; // silent loading
  }
  const { viewer: user } = props;
  if (user == null)
    throw new Error(`NavbarPopover-${type}: user is not logged in`);
  const { student } = user;
  if (student == null || student.__typename !== 'Student')
    throw new Error(`NavbarPopover-${type}: user is not a student`);
  const { activitySummary, classes, assignedTasks } = student;
  const { totalTimeSpent = 0 } = activitySummary || {};
  const showLeaderboard =
    _(classes).filter(klass => klass.hasEnabledLeaderboard).length > 0;
  const workbookTask = _(assignedTasks).find(t =>
    WORKBOOK_TASKS.includes(t.type),
  );
  const assignedWorkoutUrl =
    workbookTask != null && workbookTask.workout != null
      ? getWorkoutUrl(workbookTask.workout.id)
      : null;
  return (
    <div ref={anchorElementRef}>
      <NavbarElement
        type={type}
        totalTimeSpent={totalTimeSpent}
        userKey={user}
      />
      {isOpen && (
        <Popover
          onDismiss={onClose}
          anchorElementRef={anchorElementRef}
          shouldDismissOnTapOut
          popoverOrigin={['top', 'right']}
          anchorOrigin={['bottom', 'center']}
          shouldDismissOnOwnScroll={false}
          shouldDismissOnScroll={false}
          shouldDismissOnTab={false}
          shouldDismissOnEsc
          renderOverlay={false}
          vOffset={TRIANGLE_POINTER_HEIGHT}
          hOffset={125}
        >
          <PopoverContent
            totalTimeSpent={totalTimeSpent}
            assignedWorkoutUrl={assignedWorkoutUrl}
            showLeaderboard={showLeaderboard}
            student={student} // to pass fragment to leaderboard
            type={type}
            userKey={user}
          />
        </Popover>
      )}
    </div>
  );
}
function NavbarElement({
  type,
  totalTimeSpent,
  userKey,
}: {
  type: PopoverTypes;
  totalTimeSpent: number;
  userKey: NavbarPopover_NavbarElement_user$key;
}) {
  const isSkillsPopover = type === 'SKILLS';
  const { props } = useQuery<NavbarPopoverContentQuery>(
    NAVBAR_POPOVER_CONTENT_QUERY,
    {
      startOfWeek: START_OF_WEEK,
      now: NOW,
      previewingWithProblemData: false,
      growthPeriod: 120,
    },
    {
      skip: !isSkillsPopover,
    },
  );
  const { points } = useFragment(
    graphql`
      fragment NavbarPopover_NavbarElement_user on User {
        points {
          current
        }
      }
    `,
    userKey,
  );
  const totalPoints = points?.current ?? 0;
  const student =
    props?.viewer?.profile?.__typename === 'Student'
      ? props.viewer.profile
      : null;
  const skillsCollected = isSkillsPopover
    ? student?.activitySummary?.skillsDemonstrated
    : null;

  function renderContent() {
    switch (type) {
      case 'ACTIVITY':
        return (
          <>
            <Pencil size={28} />
            <HeadingS color="grey90">
              <Nowrap>{formatDuration(totalTimeSpent, 'h min')}</Nowrap>
            </HeadingS>
          </>
        );
      case 'SKILLS':
        return (
          <>
            <SkillBadge />
            <HeadingS color="grey90">
              <Nowrap>{skillsCollected?.length ?? 0}</Nowrap>
            </HeadingS>
          </>
        );
      case 'POINTS':
        return (
          <>
            <LightningBoltSvg style={{ height: 28, width: 'auto' }} />
            <HeadingS color="grey90">
              <Nowrap>{totalPoints === 0 ? '-' : totalPoints}</Nowrap>
            </HeadingS>
          </>
        );
      default:
        assertUnreachable(type);
    }
  }
  return (
    <HStack center gap={8}>
      {renderContent()}
    </HStack>
  );
}
const POPOVER_WIDTH = 415;
const PopoverWrapper = styled.div({
  width: POPOVER_WIDTH,
  padding: 24,
  borderRadius: 16,
  boxShadow: '0px 8.75px 49px rgba(62, 62, 76, 0.15)',
  background: colors.white,
  position: 'relative', // to position the triangle pointer
  cursor: 'default', // to reset the cursor pointer style in the nav element
});
const ACTIVITY_ITEM_BORDER_RADIUS = 8;
const ActivityItem = styled('div', {
  shouldForwardProp: p => p !== 'color',
})<{
  color: keyof typeof colors;
}>(({ color }) => ({
  flexShrink: 0,
  background: colors[color],
  borderRadius: ACTIVITY_ITEM_BORDER_RADIUS,
  transition: 'height 0.5s ease-in-out',
  overflow: 'hidden',
}));
const ActivityItemContent = styled('div', {
  shouldForwardProp: p => p !== 'padding',
})<{
  padding?: number | string | undefined;
}>(({ padding = 16 }) => ({
  padding,
}));
const TRIANGLE_POINTER_WIDTH = 40;
const TRIANGLE_POINTER_HEIGHT = 23;
// We need TRIANGLE_POINTER_OFFSET to position the triangle pointer because
// we need to hide base of the triangle svg which has rounded borders at each corner
const TRIANGLE_POINTER_BORDER_RADIUS = 8;
const TRIANGLE_POINTER_OFFSET =
  TRIANGLE_POINTER_HEIGHT - TRIANGLE_POINTER_BORDER_RADIUS;
const PopoverTrianglePointerWrapper = styled.div({
  position: 'absolute',
  width: TRIANGLE_POINTER_WIDTH,
  height: TRIANGLE_POINTER_HEIGHT,
  top: -TRIANGLE_POINTER_OFFSET,
  bottom: 'auto',
  right: 125,
  marginRight: -TRIANGLE_POINTER_WIDTH / 2,
});
const triangleShape = (
  <svg
    width={TRIANGLE_POINTER_WIDTH}
    height={TRIANGLE_POINTER_HEIGHT}
    viewBox={`0 0 ${TRIANGLE_POINTER_WIDTH} ${TRIANGLE_POINTER_HEIGHT}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.2858 1.50727C18.8184 0.0915567 21.1816 0.0915581 22.7142 1.50727L38.4699 16.0618C41.1441 18.5321 39.3962 23 35.7557 23H4.24427C0.603751 23 -1.14408 18.5321 1.53007 16.0618L17.2858 1.50727Z"
      fill={colors.white}
    />
  </svg>
);
type PopoverContentProps = {
  totalTimeSpent: number;
  assignedWorkoutUrl: string | null;
  showLeaderboard: boolean;
  student: NonNullable<
    NonNullable<NavbarPopoverQueryResponse['viewer']>['student']
  >;
  type: PopoverTypes;
  userKey: NavbarPopover_PopoverContent_user$key;
};
function PopoverContent({
  totalTimeSpent,
  assignedWorkoutUrl,
  showLeaderboard,
  student,
  type,
  userKey,
}: PopoverContentProps) {
  assert(student.__typename !== '%other');
  const isLeaderboardOpen = useBoolean();
  const isActivityPopover = type === 'ACTIVITY';
  const isSkillsPopover = type === 'SKILLS';
  const isPointsPopover = type === 'POINTS';
  const { props, error } = useQuery<NavbarPopoverContentQuery>(
    NAVBAR_POPOVER_CONTENT_QUERY,
    {
      startOfWeek: START_OF_WEEK,
      now: NOW,
      previewingWithProblemData: false,
      growthPeriod: 120,
    },
  );
  const { points } = useFragment(
    graphql`
      fragment NavbarPopover_PopoverContent_user on User {
        points {
          current
        }
      }
    `,
    userKey,
  );
  const totalPoints = points?.current ?? 0;
  const { trackStructEvent } = useSnowplow();
  const [{ gamificationEnableLeaderboardModalV2 }] = useFeatureFlagsV2();
  const LeaderboardModal = gamificationEnableLeaderboardModalV2
    ? LeaderboardModalV2
    : LeaderboardModalV1;
  if (error !== null) {
    Logger.error(`NavbarPopover Error :: ${error.message}`);
    return null;
  }
  // Loading state for when the query is in-flight
  if (props == null) {
    return (
      <PopoverWrapper>
        <PopoverTrianglePointerWrapper>
          {triangleShape}
        </PopoverTrianglePointerWrapper>

        <VStack center>Loading...</VStack>
      </PopoverWrapper>
    );
  }
  const lanternStudent =
    props.lantern?.lanternStudent?.__typename === 'LanternStudent'
      ? props.lantern.lanternStudent
      : null;
  const skillsCollected =
    props.viewer?.profile?.__typename === 'Student'
      ? props.viewer.profile.activitySummary?.skillsDemonstrated
      : null;

  const checkInsCompleted =
    props.viewer?.profile?.__typename === 'Student'
      ? props.viewer.profile.activitySummary?.checkInsCompleted ?? 0
      : 0;
  const learningFocus = lanternStudent?.learningFocus;
  const learningFocusStatus = lanternStudent?.learningFocusStatus;
  const recommendation = learningFocus?.studentSubtopicRecommendations?.[0];
  const practiceLabel =
    totalTimeSpent === 0 ? 'Start practicing' : 'Continue practicing';
  const workoutUrl =
    assignedWorkoutUrl != null
      ? assignedWorkoutUrl
      : recommendation != null
      ? getSelfDirectedAdaptiveTaskUrl({
          subtopicId: recommendation.subtopic.id,
          isSubtopicRecommendation: true,
          recommendationCategory: recommendation.category,
        })
      : null;
  const isLoadingRecommendation = assignedWorkoutUrl == null && props == null;
  function renderHeader() {
    switch (type) {
      case 'ACTIVITY':
        return (
          <>
            <HeadingS>Your activity this week</HeadingS>
            <BodyM color="grey90" center>
              Your activity across tasks, recommendations and check-ins
            </BodyM>
          </>
        );
      case 'SKILLS':
        return (
          <HeadingS>
            {skillsCollected?.length === 1 ? (
              <>1 skill collected this week</>
            ) : (
              <>{skillsCollected?.length ?? 0} skills collected this week</>
            )}
          </HeadingS>
        );
      case 'POINTS':
        return (
          <>
            {totalPoints === 0 ? (
              <HeadingS>Collect Points</HeadingS>
            ) : (
              <HeadingS
                style={{ alignItems: 'center', display: 'flex', gap: 8 }}
              >
                <LightningBoltSvg style={{ height: 28, width: 'auto' }} />
                {totalPoints} points this week
              </HeadingS>
            )}

            <BodyM color="grey90" center>
              Earn points by working on assigned and recommended practice
            </BodyM>
          </>
        );
      default:
        assertUnreachable(type);
    }
  }
  return (
    <PopoverWrapper>
      <PopoverTrianglePointerWrapper>
        {triangleShape}
      </PopoverTrianglePointerWrapper>

      <VStack gap={16}>
        <VStack center justify="center" gap={8}>
          {renderHeader()}
        </VStack>
        {isActivityPopover && (
          <div>
            <ActivityItem color="almond50">
              <ActivityItemContent>
                <HStack center>
                  <NoShrink>
                    <Pencil size={24} />
                  </NoShrink>
                  <HSpacer width={8} />
                  <HeadingS>
                    <Nowrap>{formatDuration(totalTimeSpent, 'h min')}</Nowrap>
                  </HeadingS>
                  <HSpacer width={8} />
                  <BodyM>practice</BodyM>
                  <HSpacer width={15} grow />

                  {isLoadingRecommendation && (
                    <MinorSpinner scale={0.5} noPadding />
                  )}

                  {workoutUrl != null && (
                    <AnchorButton
                      height={24}
                      size="lanternSmall"
                      padding={4}
                      href={workoutUrl}
                      label={practiceLabel}
                      onClick={() => {
                        trackStructEvent({
                          category: 'student_nav_stats',
                          action: 'clicked_start_practicing',
                        });
                      }}
                    >
                      <Nowrap>{practiceLabel}</Nowrap>
                    </AnchorButton>
                  )}
                </HStack>
              </ActivityItemContent>
            </ActivityItem>
            <VSpacer height={8} />
            <ActivityItem color="almond50">
              <ActivityItemContent>
                <HStack center>
                  <NoShrink>
                    <Lightning />
                  </NoShrink>
                  <HSpacer width={8} />
                  <HeadingS>{totalPoints}</HeadingS>
                  <HSpacer width={8} />
                  <BodyM>points</BodyM>
                  <HSpacer width={16} grow />
                  {showLeaderboard && (
                    <Button
                      height={24}
                      size="lanternSmall"
                      padding={4}
                      onClick={() => {
                        isLeaderboardOpen.setTrue();
                        trackStructEvent({
                          category: 'student_nav_stats',
                          action: 'clicked_view_leaderboard',
                        });
                      }}
                      label="View leaderboard"
                    >
                      <Nowrap>View leaderboard</Nowrap>
                    </Button>
                  )}
                </HStack>
              </ActivityItemContent>
            </ActivityItem>
          </div>
        )}
        {isSkillsPopover && skillsCollected != null && (
          <VStack gap={8}>
            <ActivityItem color="eggplant10">
              <ActivityItemContent padding={16}>
                <HStack center gap={8}>
                  <CheckInsBadge />
                  <BodyM color="grey90" bold>
                    <InlineHeadingS>
                      {checkInsCompleted > 0 ? checkInsCompleted : '-'}
                    </InlineHeadingS>{' '}
                    check-in{checkInsCompleted !== 1 ? 's' : ''}
                  </BodyM>
                  <HSpacer grow width={2} />
                  {learningFocus && learningFocusStatus === 'VALID' && (
                    <NavbarPopoverStartCheckInButton
                      learningFocus={learningFocus}
                      hasCheckInsCompletedInLastPeriod={checkInsCompleted > 0}
                    />
                  )}
                </HStack>
              </ActivityItemContent>
            </ActivityItem>

            <ActivityItem color="eggplant10">
              <ActivityItemContent padding={16}>
                <HStack center gap={8}>
                  <SkillBadge />
                  <BodyM color="grey90" bold>
                    <InlineHeadingS>
                      {skillsCollected.length > 0
                        ? skillsCollected.length
                        : '-'}
                    </InlineHeadingS>{' '}
                    skill{skillsCollected.length !== 1 ? 's' : ''} collected
                  </BodyM>
                  <HSpacer grow width={2} />
                  <AnchorButton
                    size="small"
                    padding={20}
                    type="secondary"
                    href={getStudentSkillsUrl({})}
                  >
                    View all skills
                  </AnchorButton>
                </HStack>
              </ActivityItemContent>

              <ActivityItemDivider />

              <ActivityItemContent
                padding={`26px 18px ${
                  skillsCollected.length > 2 ? 16 : 24
                }px 18px`}
              >
                {skillsCollected.length === 0 ? (
                  <VStack center justify="center">
                    <VStack center justify="center" height={93} width={255}>
                      <BodyM bold center color="grey10">
                        Start a check-in and see the skills you collected here
                      </BodyM>
                    </VStack>
                  </VStack>
                ) : (
                  <SkillsCollected skillsCollected={skillsCollected} />
                )}
              </ActivityItemContent>
            </ActivityItem>
          </VStack>
        )}
        {isPointsPopover && showLeaderboard && (
          <Button
            type="secondary"
            styles={{ alignSelf: 'center' }}
            onClick={() => {
              isLeaderboardOpen.setTrue();
              trackStructEvent({
                category: 'gamification',
                action: 'clicked_view_leaderboard_in_points_widget',
              });
            }}
          >
            View Leaderboard
          </Button>
        )}
      </VStack>

      {showLeaderboard && (
        <LeaderboardModal
          isOpen={isLeaderboardOpen.value}
          onClose={isLeaderboardOpen.setFalse}
          studentKey={student}
        />
      )}
    </PopoverWrapper>
  );
}
function SkillsCollected({
  skillsCollected,
}: {
  skillsCollected: SkillsCollectedT;
}) {
  const { props } = useQuery<NavbarPopoverSkillsCollectedQuery>(
    NAVBAR_POPOVER_SKILLS_COLLECTED_QUERY,
    {
      filters: (skillsCollected || []).map(skill => ({
        curriculumNodeIds: [skill.outcome.id],
      })),
      previewingWithProblemData: false,
      growthPeriod: 120,
    },
  );
  if (props == null || skillsCollected == null) return <MinorSpinner />;
  const userStatuses =
    props.lantern.lanternStudent?.__typename === 'LanternStudent'
      ? props.lantern.lanternStudent.userStatuses
      : null;
  if (userStatuses == null) return null;
  return (
    <Slider
      items={makeTriplets(skillsCollected).map(skillsTriplet => ({
        key: skillsTriplet.map(skill => skill?.id ?? '').join('-'),
        node: (
          <HStack center gap={10}>
            {skillsTriplet.map(skill =>
              skill == null ? null : (
                <CollectedSkill
                  key={skill.id}
                  skill={skill}
                  proficiency={getProficiencyForOutcome({
                    userStatuses,
                    outcomeId: skill?.outcome.id,
                  })}
                />
              ),
            )}
          </HStack>
        ),
      }))}
    />
  );
}
function Pencil({ size }: { size: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.6522 10.5663L26.5184 8.68893C28.4944 6.70123 28.4944 3.47855 26.5183 1.49064C24.5428 -0.496899 21.3391 -0.497009 19.3631 1.49097L17.4971 3.36819L24.6522 10.5663Z"
        fill="#F23B3B"
      />
      <path
        d="M26.5184 8.68893L24.6522 10.5663L17.4971 3.36819L19.3631 1.49099C19.3965 1.45745 19.4301 1.42445 19.4642 1.39204C20.3179 0.578457 21.3733 0.127583 22.4507 0.0237905C23.2951 -0.0584131 24.156 0.0722777 24.9474 0.41584C25.719 0.749498 26.4198 1.28219 26.9672 2.00675C28.4809 4.00216 28.3313 6.8653 26.5184 8.68893Z"
        fill="#DD7185"
      />
      <path
        d="M15.7275 5.1483L17.6568 3.20741L24.812 10.4063L22.8827 12.3471L22.8819 12.3463L20.9528 14.287L13.7979 7.08917L15.7275 5.1483Z"
        fill="#DDDDDD"
      />
      <path
        d="M15.7183 5.16261L22.8478 12.3348L20.9187 14.2754L20.9131 14.2699L9.41446 25.8374L2.28809 18.6678L13.9186 6.96764L13.9213 6.97039L15.7183 5.16261Z"
        fill="#F4B42E"
      />
      <path
        d="M0.156443 25.9389C0.163498 25.9078 2.27941 18.6981 2.28838 18.6676L9.41475 25.8368L2.06427 27.8582C1.17877 28.1192 -0.523267 28.2706 0.156443 25.9389Z"
        fill="#FFE3BC"
      />
      <path
        d="M2.06425 27.8585L4.05547 27.3109L0.733209 23.9686C0.724241 23.9991 0.163476 25.9082 0.156421 25.9391C-0.523234 28.2708 1.1788 28.1195 2.06425 27.8585Z"
        fill="#3E3E4C"
      />
    </svg>
  );
}
function Lightning() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_467_30174)">
        <path
          d="M17.8912 7.66249H14.8882L16.2919 2.61812C16.6582 1.3024 15.6687 3.05176e-05 14.303 3.05176e-05H7.78398C6.53399 3.05176e-05 5.47819 0.928361 5.31806 2.16819L4.01947 12.2249C3.84022 13.6133 4.92151 14.8434 6.32147 14.8434H9.6737L9.51177 22.8404C9.48787 24.0131 11.0422 24.4465 11.6287 23.4306L19.3111 10.1214C19.9419 9.0284 19.1532 7.66249 17.8912 7.66249Z"
          fill="#F8C983"
        />
        <path
          d="M17.8917 7.66251H14.8886L16.2924 2.61812C16.6587 1.3024 15.6692 3.05176e-05 14.3035 3.05176e-05H10.7134C10.3018 0.562288 10.0225 1.32551 10.0797 2.35602C10.2384 5.21072 12.3514 6.40077 11.6756 9.79164C11.4896 10.7253 12.21 11.5947 13.1622 11.5947C13.1622 11.5947 14.4853 11.5208 14.4853 13.0134L14.0521 19.2331L19.3116 10.1213C19.9424 9.02842 19.1537 7.66251 17.8917 7.66251Z"
          fill="#F4B42E"
        />
      </g>
      <defs>
        <clipPath id="clip0_467_30174">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
function CheckInsBadge() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="28"
      viewBox="0 0 25 28"
      fill="none"
    >
      <path
        d="M24.24 7.38529C24.1962 7.30854 24.1328 7.24478 24.0563 7.20053C23.9798 7.15628 23.8929 7.13312 23.8045 7.13341L15.383 7.15729C15.3174 7.15693 15.2524 7.16974 15.1918 7.19496C15.1313 7.22019 15.0764 7.25732 15.0305 7.30414C14.9841 7.35063 14.9475 7.40582 14.9226 7.46655C14.8977 7.52727 14.885 7.59232 14.8854 7.65796L14.8979 12.1122L9.94791 12.1264C9.88233 12.1261 9.81737 12.1389 9.75683 12.1641C9.6963 12.1894 9.64143 12.2264 9.59546 12.2732C9.54912 12.3197 9.51244 12.3749 9.48753 12.4356C9.46262 12.4963 9.44999 12.5614 9.45034 12.627L9.46447 17.5808C9.4646 17.6463 9.47765 17.7112 9.50289 17.7718C9.52814 17.8323 9.56507 17.8872 9.61157 17.9334C9.65808 17.9797 9.71325 18.0163 9.77392 18.0411C9.83459 18.066 9.89957 18.0787 9.96514 18.0784L23.8353 18.0391C23.8543 18.0392 23.8732 18.0382 23.8919 18.0359C23.9749 18.0262 24.0541 17.9958 24.1223 17.9475C24.1904 17.8992 24.2454 17.8346 24.2821 17.7595C24.3189 17.6845 24.3362 17.6015 24.3325 17.518C24.3288 17.4345 24.3043 17.3533 24.2611 17.2818L21.4296 12.5932L24.2351 7.88852C24.2803 7.81257 24.3046 7.72597 24.3055 7.63757C24.3063 7.54916 24.2837 7.46211 24.24 7.38529Z"
        fill="#3F4694"
      />
      <path
        d="M15.864 12.4287C15.8274 12.3348 15.7632 12.2541 15.6799 12.1974C15.5966 12.1406 15.498 12.1104 15.3972 12.1108L9.94816 12.126C9.88259 12.1257 9.81762 12.1385 9.75709 12.1638C9.69656 12.189 9.64169 12.2261 9.59572 12.2728C9.54938 12.3193 9.5127 12.3745 9.48779 12.4352C9.46288 12.4959 9.45024 12.561 9.4506 12.6266L9.46473 17.5804C9.46509 17.677 9.49348 17.7715 9.54648 17.8523C9.59947 17.9332 9.67479 17.9969 9.76328 18.0357C9.85177 18.0746 9.94964 18.0869 10.045 18.0712C10.1404 18.0556 10.2291 18.0126 10.3005 17.9474L15.7354 12.9785C15.8099 12.9106 15.8621 12.8217 15.885 12.7236C15.908 12.6254 15.9006 12.5226 15.864 12.4287Z"
        fill="#36A6B2"
      />
      <path
        d="M15.7231 2.34829C15.6766 2.30194 15.6214 2.26526 15.5607 2.24036C15.5 2.21545 15.4349 2.20281 15.3693 2.20317L2.48946 2.23986C2.42389 2.23997 2.35899 2.25303 2.29848 2.27826C2.23796 2.3035 2.18302 2.34044 2.1368 2.38694C2.09058 2.43345 2.05399 2.48862 2.02912 2.54929C2.00426 2.60997 1.99161 2.67495 1.9919 2.74052L2.02034 12.6475C2.02047 12.713 2.03352 12.7779 2.05876 12.8385C2.084 12.899 2.12094 12.9539 2.16744 13.0001C2.21395 13.0464 2.26912 13.083 2.32979 13.1078C2.39046 13.1327 2.45544 13.1454 2.52101 13.1451L15.4002 13.109C15.4191 13.1091 15.438 13.108 15.4568 13.1058C15.5786 13.0916 15.6909 13.033 15.7723 12.9413C15.8536 12.8495 15.8983 12.731 15.8978 12.6084L15.87 2.70077C15.8699 2.63524 15.8569 2.57037 15.8317 2.50988C15.8065 2.44939 15.7696 2.39447 15.7231 2.34829Z"
        fill="#398984"
      />
      <rect
        x="0.0286255"
        y="0.0224609"
        width="2.99578"
        height="27.9607"
        rx="1.49789"
        fill="#1ABC9C"
      />
    </svg>
  );
}
function makeTriplets<T>(array: readonly T[]) {
  const newArray: Array<[T, T | undefined, T | undefined]> = [];
  for (let i = 0; i < array.length; i += 3) {
    newArray.push([unwrap(array[i]), array[i + 1], array[i + 2]]);
  }
  return newArray;
}

const SkillTitle = styled.div({
  ...multilineTextOverflow(4),
  height: 76,
  textAlign: 'center',
  fontWeight: fontWeight.semibold,
  fontSize: fontSize.small,
  lineHeight: '18px',
  color: colors.eggplant,
});
const SkillAnchor = styled.a<{
  href: string;
}>({
  textDecoration: 'none',
});

const ActivityItemDivider = styled.div({
  width: '100%',
  height: 1,
  backgroundColor: colors.eggplant20,
});
function SkillLink(props: ComponentProps<typeof Link>) {
  return <Link {...props} css={{ textDecoration: 'none' }} />;
}
function Skill({
  skillLink,
  children,
}: {
  skillLink: string;
  children: React.ReactNode;
}) {
  const [{ hasStudentAppSpa }] = useFeatureFlags();
  return hasStudentAppSpa ? (
    <SkillLink to={skillLink}>{children}</SkillLink>
  ) : (
    <SkillAnchor href={skillLink}>{children}</SkillAnchor>
  );
}
function CollectedSkill({
  skill,
  proficiency,
}: {
  skill: SkillT;
  proficiency: ReturnType<typeof getProficiencyForOutcome>;
}) {
  return (
    <Skill
      skillLink={getStudentSkillUrl({
        substrandId: skill.outcome.gradeSubstrand.substrand.id,
        strandId: skill.outcome.gradeSubstrand.substrand.strand.id,
        outcomeId: skill.outcome.id,
      })}
    >
      <VStack width={100} height={154} center justify="center">
        <SkillProficiencyIndicator value={proficiency} width={56} />
        <VSpacer height={10} />
        <BodyM>
          <SkillTitle>{skill.outcome.title}</SkillTitle>
        </BodyM>
      </VStack>
    </Skill>
  );
}
const NAVBAR_POPOVER_SKILLS_COLLECTED_START_CHECK_IN_BUTTON_QUERY = graphql`
  query NavbarPopoverSkillsCollectedStartCheckInButtonQuery($strandId: ID!) {
    lantern {
      student: viewer {
        __typename
        ... on LanternStudent {
          strandStatus(strandId: $strandId) {
            diagnosticQuestionsCompleted
            status
          }
        }
      }
    }
  }
`;

function NavbarPopoverStartCheckInButton({
  learningFocus,
  hasCheckInsCompletedInLastPeriod,
}: {
  learningFocus: LearningFocus;
  hasCheckInsCompletedInLastPeriod: boolean;
}) {
  const strandId = learningFocus.strand.id;
  const { trackStructEvent } = useSnowplow();
  const { props } =
    useQuery<NavbarPopoverSkillsCollectedStartCheckInButtonQuery>(
      NAVBAR_POPOVER_SKILLS_COLLECTED_START_CHECK_IN_BUTTON_QUERY,
      { strandId },
    );
  const student =
    props?.lantern?.student?.__typename === 'LanternStudent'
      ? props.lantern.student
      : null;
  const strandStatus = student?.strandStatus;
  const substrandId = learningFocus.id;
  if (strandStatus == null) return null;
  const checkInButtonConfig = makeCheckInButtonConfig({
    studentStrandStatus: strandStatus,
    strandId,
    substrandId,
  });
  const checkInButtonProps: ComponentProps<typeof StartCheckInButton> = {
    size: 'small',
    isDisabled: !learningFocus.canStartCheckIn,
    checkInUrl: checkInButtonConfig.url,
    label: checkInButtonConfig.label,
    tooltipContent: !learningFocus.canStartCheckIn
      ? START_CHECK_IN_DISABLED_TOOLTIP_CONTENT
      : undefined,
    secondary: hasCheckInsCompletedInLastPeriod,
    showIcon: false,
    padding: 20,
    onClick: () => {
      trackStructEvent({
        category: 'student_nav_stats',
        action: 'clicked_start_check_in',
      });
    },
  };
  return <StartCheckInButton {...checkInButtonProps} />;
}
