import { useEffect } from 'react';

import { usePrintMode } from 'ms-helpers/PrintMode';
import { Logger } from 'ms-utils/app-logging';

import { LazyBunnyVideo } from './LazyBunnyVideo';
import WistiaVideo from './WistiaVideo';
import YoutubeVideo from './YoutubeVideo';

export type AspectRatio = string; // '4:3' | '16:9' | '9:16';

export type Props = {
  // the video's id on the video hosting service
  id: string;
  // Which service the video is hosted on. Eventually youtube should be removed
  // as it gets blocked by school firewalls, but we have legacy content that
  // is still embedding youtube so we have to deal with it for now.
  videoHostingService: string; // 'bunny' | 'wistia' | 'youtube';
  // Our screencasts should be 4:3 moving forward. Legacy content can be 16:9
  // as well as when we embed videos produced by third parties.
  aspectRatio: AspectRatio;
  hasPlaylist?: boolean | undefined;
  useIframeEmbed?: boolean | undefined;
  autoPlay?: boolean | undefined;
  resumableWith?: { resumeToken: string } | undefined;
  silentAutoPlay?: boolean | undefined;
  onVideoEnd?: (() => void) | undefined;
};

const renderVideo = ({
  id,
  videoHostingService,
  aspectRatio,
  hasPlaylist,
  useIframeEmbed = true,
  autoPlay,
  resumableWith,
  silentAutoPlay,
  onVideoEnd,
}: Props) => {
  switch (videoHostingService) {
    case 'youtube': {
      return (
        <YoutubeVideo id={id} aspectRatio={aspectRatio} autoPlay={autoPlay} />
      );
    }
    case 'wistia': {
      return (
        <WistiaVideo
          id={id}
          aspectRatio={aspectRatio}
          hasPlaylist={hasPlaylist}
          useIframeEmbed={useIframeEmbed}
          autoPlay={autoPlay}
          resumableWith={resumableWith}
          silentAutoPlay={silentAutoPlay}
        />
      );
    }
    case 'bunny': {
      return (
        <LazyBunnyVideo
          id={id}
          aspectRatio={aspectRatio}
          autoPlay={autoPlay}
          resumableWith={resumableWith}
          silentAutoPlay={silentAutoPlay}
          onVideoEnd={onVideoEnd}
        />
      );
    }
    default: {
      return null;
    }
  }
};

export default function VideoEmbed(props: Props) {
  const { id, videoHostingService } = props;

  const printMode = usePrintMode();

  // We want to eliminate Youtube and Wistia videos from all our DB content.
  // We'll add Sentry error logging to help us eliminate any of these
  // lingering assets we may have missed.
  useEffect(() => {
    const deprecatedServices = ['youtube', 'wistia'];
    if (deprecatedServices.includes(videoHostingService)) {
      Logger.error(`🚨 Deprecated video asset loaded`, {
        tags: {
          videoId: id,
          videoHostingService,
        },
      });
    }
  }, [videoHostingService, id]);

  if (printMode) return <em>(Watch this video online)</em>;

  return renderVideo(props);
}
