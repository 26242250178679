import type { ResponseError } from 'ms-utils/relay/extractResponseErrorMessages';

import type { QuestionType, StudentAnswer } from './types';

const START_CHECK_IN_DISABLED_TOOLTIP_CONTENT =
  'You completed this check-in recently. Try some recommended practice and check-in again later.';

const CHECK_IN_CREATION_ERROR = {
  key: 'CHECK_IN_CREATION_ERROR',
  message: 'Something went wrong, please try again.',
} as const;

export type CheckInResponseError =
  | ResponseError
  | typeof CHECK_IN_CREATION_ERROR;
export type CheckInResponseErrors = ReadonlyArray<CheckInResponseError>;

type MilliSeconds = number;
function getQuestionDuration(lastInteractionTime: Date): MilliSeconds {
  return new Date().getTime() - lastInteractionTime.getTime();
}

function isStudentAnswerEmpty({
  studentAnswer,
  questionType,
}: {
  studentAnswer: StudentAnswer;
  questionType: QuestionType;
}): boolean {
  if (studentAnswer === null) return true;

  return (
    (questionType === 'MathExpressionQuestion' && studentAnswer === '') ||
    (questionType === 'MultipleChoiceQuestion' && studentAnswer.length === 0)
  );
}

export {
  START_CHECK_IN_DISABLED_TOOLTIP_CONTENT,
  CHECK_IN_CREATION_ERROR,
  getQuestionDuration,
  isStudentAnswerEmpty,
};
